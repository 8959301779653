
export default function initDesktopNav() {
  // Variables
  let header = document.querySelector(".header");
  let headerHeight = header.offsetHeight;
  let submenuBG = document.querySelector(".nav--main__submenu-bg");
  let submenuArray = document.querySelectorAll(".nav--main .sub-menu");
  let itemWithChildren = document.querySelectorAll(".nav--main .menu-item-has-children");

  /**
   * Hide all submenus.
   * Must do this here because otherwise all object heights are 0.
   */
  // submenuArray.forEach((submenu) => {
  //   submenu.setAttribute("style", "display:none");
  // });

  /**
   * Set the height of the submenu background relative to
   * the submenu that was just opened
   */
  itemWithChildren.forEach((item) => {

    // remove href
    item.firstChild.href = 'javascript: void(0)';

    let submenu = item.querySelector(".sub-menu");

    item.onmouseenter = () => {
      let submenuHeight = submenu.offsetHeight;
      submenuBG.setAttribute("style", `top:${headerHeight}px; height:${submenuHeight}px;`);
    };

    item.onmouseleave = () => {
      submenuBG.setAttribute("style", `top:${headerHeight}px; height:0px;`);
    };
  });



}
