import $ from 'jquery'

const $doc = $(document);

function openFooterDropdowns() {
  const submenu = $(this).next('.sub-menu');
  submenu.slideToggle(500);
  $(this).toggleClass('is-active');
  $('.nav--footer .sub-menu').not(submenu).slideUp(500);
  $(this).attr('aria-expanded',
    $(this).attr('aria-expanded')=='false' ? 'true' : 'false'
  );
  $('.nav--footer .dropdown-toggle').not(this).attr('aria-expanded', 'false');
  $('.nav--footer .dropdown-toggle').not(this).removeClass('is-active');
}

export default function initFooterNav() {
  $doc.on('click', '.nav--footer .menu-item-has-children .dropdown-toggle', openFooterDropdowns )
}
